jQuery(function($) {
  // Perform AJAX login on form submit
  $('form#login').on('submit', function(e){
      $('form#login p.status').show().text('Sending user info, please wait...');
      $.ajax({ 
          type: 'POST',
          dataType: 'json',
          url: smg_ajax_login.ajaxurl,
          data: {
              'action': smg_ajax_login.action,
              'username': $('form#login #username').val(), 
              'password': $('form#login #password').val(), 
              'security': $('form#login #security').val() },
              
              success: function(data) {
                  $('form#login p.status').text(data.message);
                  if (data.loggedin == true) {
                      document.location.href = smg_ajax_login.homeurl;
                  }
              },
      });
      e.preventDefault();
  });

});